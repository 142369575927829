export default {
  NOTIFICATIONS: 'Notifications',
  MARK_ALL_AS_READ: 'Mark all as read',
  APPLICANT_CREATED: 'Applicant profile created',
  APPLICANT_STEP_PASSED: 'An applicant has completed a verification step <strong>{{step}}</strong>',
  APPLICANT_STATUS_UPDATED:
    'The user <strong>{{user}}</strong> verification status was changed to <strong>{{status}}</strong',

  DOCUMENT_WARNING: '<strong>{{warning}}</strong> found during <strong>{{fullName}}</strong> verification',
  APPLICANT_DUPLICATE_DOCUMENT: 'Applicant duplicate document',
  APPLICANT_DUPLICATE_PHONE: 'Applicant duplicate phone',
  APPLICANT_DUPLICATE_EMAIL: 'Applicant duplicate email',

  // TODO
  AML_CANNOT_STARTED: 'AML cannot start',
  AML_RESULT_UPDATED: 'AML result updated',
  AML_POSITIVE_CASES: 'AML positive cases',
  APPLICANT_AML_UPDATED: 'Applicant AML updated',
  ADDITIONAL_DOCUMENT_STEP_ERROR: 'Document step error',

  APPLICANT_IN_REVIEW: 'An applicant is ready for review.',
  THERE_ARE_NO_UNREAD_NOTIFICATIONS: 'There are no unread notifications',
  APPLICANT_ON_HOLD: 'Applicant on hold',

  INIT: 'Init',
  INCOMPLETE: 'Incomplete',
  IN_PROGRESS: 'In progress',
  ON_HOLD: 'On hold',
  COMPLETED: 'Completed',
  IN_REVIEW: 'In Review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ACCEPTED: 'Accepted',
};
