export default {
  NAME: 'Ім`я',
  PROGRAM: 'Програма',
  SOURCES: 'Джерела',
  GO_TO_LINK: 'Перейти за посиланням',
  SHOW_DOCUMENT: 'Показати документ',
  NATIONALITY: 'Національність',
  NAME_SURNAME: 'Ім`я Прізвище',
  GENDER: 'Стать',
  BIRTH_INFORMATION: 'Інформація про народження',
  COUNTRY_NATIONALITY: 'Країна та Національність',
  ORIGINALSCRIPTS: 'Оригінальні Сценарії',
  AKA_NAMES: 'Відомий як',
  PROFILE: 'Профіль',
  PROFILE_NOTES: 'Примітки до профілю',
  UPDATED: 'Оновлено',
  REMOVED: 'Видалено',
  CONFIRM: 'Підтвердити',
  REQUIRED: 'Обов`язковий',
  TIMELINE: 'Хронологія',
  PHONE: 'Телефон',
  MORE: 'Більше',

  VERIFICATIONS: 'Верифікації',
  APPLICANTS: 'Заявники',
  APPLICANT_ID: 'ID заявника',
  VERIFICATION_DETAILS: 'Верифікація',

  DOWNLOAD: 'Завантажити',
  CHANGES_HISTORY: 'Історія змін',
  HISTORY: 'Історія',
  LOGS: 'Логи',
  OLD_STEPS: 'Cтарі кроки',

  BUTTON_APPROVE: 'Затвердити',
  BUTTON_DECLINE: 'Відхилити',
  DELETE: 'Видалити',
  REOPEN: 'Відкрити',
  REVIEWER: 'Оператор',
  ASSIGN: 'Призначити',
  ASSIGN_TO_ME: 'Призначити на мене',
  EDIT: 'Редагувати',
  REJECT: 'Відхилити',

  STATUS: 'Статус',
  REVIEWER_STATUS: 'Статус розгляду',
  TRANSACTION_ID: 'ID верифікації',
  FULL_NAME: 'Повне імʼя',
  UPDATED_AT: 'Дата',
  CREATED_AT: 'Дата створення',
  PLATFORM: 'Платформа',
  APPLICATION: 'Додаток',
  RESOLVE_WARNING: 'Усунути',
  CONFIRM_WARNING_RESOLUTION: 'Підтвердити усунення попередження',
  CONFIRM_WARNING_RESOLUTION_DESCRIPTION: 'Ви впевнені, що хочете усунути попередження щодо цього профілю?',

  ALL_STATUS: 'Всі статуси',
  STATUS_INIT: 'Init',
  STATUS_INCOMPLETE: 'Incomplete',
  STATUS_IN_PROGRESS: 'In progress',
  STATUS_ON_HOLD: 'On hold',
  STATUS_COMPLETED: 'Completed',
  STATUS_IN_REVIEW: 'In Review',
  STATUS_APPROVED: 'Approved',
  STATUS_REJECTED: 'Rejected',
  STATUS_ACCEPTED: 'Accepted',

  REASON: 'Причина',
  ALL_PLATFORM: 'Всі платформи',
  ANDROID: 'Android',
  IOS: 'IOS',
  WEB: 'Web',

  SPOOFING: 'Спуфінг для liveness',
  ALL_APP_KEY: 'Всі додатки',
  WRITE_COMMENT: 'Напишіть коментар',
  GENERATE_LINK: 'Згенерувати посилання',
  REQUEST_ADDITIONAL_INFO: 'Запит додаткової інформації',
  GENERATE_LINK_TOOLTIP:
    'Згенеруйте нове посилання, щоб дозволити користувачу продовжити поточний процес перевірки.',
  ADDITIONAL_INFO_TOOLTIP:
    'Попросіть додаткову інформацію в заявника. Ця дія запускає процес перевірки on-demand і не конфліктує з основним процесом.',
  TRUST_LEVEL: 'Рівень довіри',
  PERIODICITY: 'Періодичність',
  VERIFICATION_METHOD: 'Методи перевірки',

  USER_INFORMATION: 'Інформація про користувача',
  QUESTIONNAIRE: 'Результати Опитувальника',
  EMAIL_VERIFICATION: 'Підтвердження електронної пошти',
  PHONE_VERIFICATION: 'Перевірка телефону',
  DOCUMENT_CAPTURE: 'Перевірка документів',
  CLEAN_DOCUMENT_CAPTURE: 'Фото документів',
  BARCODE_CAPTURE: 'Штрих код',
  CREDIT_CARD_CAPTURE: 'Кредитна картка',
  RFID_DOCUMENT_CAPTURE: 'Дані з RFID',
  SPECIFIC_DOCUMENT_CAPTURE: 'Нестандартний документ',
  SELFIE_CAPTURE: 'Селфі',
  FACE_CAPTURE: 'Фото обличчя',
  FACE_MATCH: 'Зіставлення обличчя',
  FACE_LIVENESS: 'Liveness',
  ADDRESS_RISK_SCORE: 'Оцінка адресного ризику',
  PASSIVE_LIVENESS: 'Liveness',
  TRANSACTION_RISK_SCORE: 'Оцінка ризику транзакції',
  TRANSACTION_MONITORING: 'Моніторинг транзакцій',
  PASSIVE_FACE_MATCH: 'Порівняння обличчя',
  SIGNATURE: 'Підпис',
  OTP_VERIFICATION: 'Перевірка Otp',
  UTILITY_BILL: 'Рахунок за комунальні послуги',
  DIIA: 'Дія',
  PHONE_SMS_OTP_VERIFICATION: 'Перевірка sms на телефон',

  AML_RESULTS: 'AML результати',
  AML_CHECK_COMPLETED_SUCCESSFULLY: 'Перевірка AML успішно завершена!',
  AML_CHECK_WAS_UNSUCCESSFULLY: 'Результат перевірки AML: Попередження',
  AML_CHECK_COMPLETED_SUCCESSFULLY_DESCRIPTION:
    'Ми маємо задоволення повідомити вам, що під час перевірки облікового запису клієнта порушень не виявлено.',
  AML_CHECK_WAS_UNSUCCESSFULLY_DESCRIPTION:
    'Особа була знайдена в деяких базах даних. Будь ласка, перегляньте результати для подальших дій.',
  AML_CANNOT_STARTED:
    "Перевірка AML не може початися, оскільки ім'я або прізвище користувача не були отримані.",
  APPLICANT_DUPLICATE_CRYPTO_ADDRESS: 'Виявлено дублікат криптовалютної адреси',

  YES_CONFIRM: 'Так, підтверджую',
  CONFIRM_VERIFICATION_FLOW_CHANGE: 'Підтвердити зміну процесу перевірки',
  CONFIRM_VERIFICATION_FLOW_CHANGE_DESCRIPTION:
    'Ви впевнені, що хочете змінити процес перевірки для цього заявника?',

  LOW_MATCHING: 'Низька ймовірність відповідності. Здається, на фото різні люди.',

  COMEBACK: 'Повернутись',

  SELECTED: 'вибрано',
  TRUST_LEVEL_FROM: 'Рівень довіри від',
  TO: 'до',
  EVENT_TYPE: 'Тип події',
  PROBABILITY: 'Ймовірність',

  DOCUMENT_CAPTURE_TABLE_HEADER_VISUAL: 'Visual',
  DOCUMENT_CAPTURE_TABLE_HEADER_MRZ: 'MRZ',
  DOCUMENT_CAPTURE_TABLE_HEADER_RFID: 'RFID',

  NOT_ASSIGNED: 'Не призначено',
  REVIEWER_NOT_ASSIGNED: 'Оператор не призначен',
  IN_REVIEW: 'Оператор: ',
  CLEAR_FILTERS: 'Очистити фільтри',
  SEARCH: 'Пошук',
  SELECT_USER: 'Обрати користувача',
  WEBHOOK_DELIVERY_STATE: 'Статус вебхука',
  NOT_FOUND: 'Не знайдено',
  USER_DELETED: 'Користувач видалений',
  NOT_VALID: 'Not valid',
  VALID: 'Valid',
  DUPLICATE_DOCUMENT: 'Дубльований документ',
  DOCUMENT_EXPIRED: 'Документ закінчився',
  COMMENT_REQUIRED_FIELD: 'Поле «Коментар» є обов’язковим для заповнення',
  ADDITIONAL_DOCUMENT_STEP_ERROR: 'Помилка кроку документа',

  APP_NAME: 'Додаток',
  ERROR_MESSAGE: 'Повідомлення про помилку',
  READ_ONLY: '<strong>Режим перегляду.</strong> призначте оператора, щоб увімкнути дії.',
  APPLICANT_IDS: 'IDs Заявника',
  EXTERNAL_USER_ID: 'ID зовнішнього користувача',
  VERIFICATIONS_STEPS: 'Етапи перевірки',
  VERIFICATIONS_TRACKER: 'Трекер',
  PERSONAL_INFO: 'Особиста інформація',
  VERIFICATION_INFO: 'Інформація про перевірку',
  DEVICE_INFO: 'Інформація про девайс',
  ME: 'Мене',
  UNKNOWN: 'Невідомо',
  NO_MATCH: 'Немає збігу',
  POTENTIAL_MATCH: 'Можливий збіг',
  FALSE_POSITIVE: 'Хибнопозитивний',
  TRUE_POSITIVE: 'Істиннопозитивний',
  TRUE_POSITIVE_APPROVE: 'Істиннопозитивний, схвалити',
  TRUE_POSITIVE_REJECT: 'Істиннопозитивний, відхилити',

  DATE_OF_BIRTH: 'Дата народження',
  LOCATION: 'Локація',
  EMAIL: 'Електронна пошта',
  TEL_NUMBER: 'Тел. номер',
  IP: 'IP',

  PERCENTAGE_OF_FACEMATCH: 'Відсоток збігу обличчя',
  REASON_FOR_DEVIATION: 'Причина відхилення від',
  DATE_TIME: 'Дата & Час',
  INFO: 'Інформація',
  COMMENTS: 'Коментарі',
  COMMENT: 'Коментар',
  EMAIL_VERIFIED: 'Емейл верифіковано',
  PHONE_NUMBER_VERIFIED: 'Номер телефону верифіковано',

  METRO: 'Метро',

  DAILY: 'Щодня',
  WEEKLY: 'Один раз на тиждень',
  MONTHLY: 'Один раз на місяць',
  QUARTERLY: 'Щоквартально',
  HALF_EARLY: 'Піврічна',
  YEARLY: 'Щороку',
  ONGOING_MONITORING: 'Постійний моніторинг',
  AML_MONITORING: 'AML моніторинг',
  AML_CHECK: 'AML перевірка',
  TURN_OFF: 'Вимкнути',
  UPDATE: 'Оновити',
  CREATE: 'Створити',
  CANCEL: 'Скасувати',
  APPLY: 'Застосувати',
  START_AML: 'Почати AML',
  ONGOING: 'Триває',
  APPLICANT_DELETE: 'Заявника успішно видалено',
  TOOLTIP_STATUS_INIT: 'Профіль апліканта створено, додаткові дії не потрібні.',
  TOOLTIP_STATUS_INCOMPLETE: 'Верифікація ініційована, але отримано не всі дані.',
  TOOLTIP_STATUS_IN_PROGRESS: 'Проводиться перевірка даних, наданих користувачем.',
  TOOLTIP_STATUS_ON_HOLD: '',
  TOOLTIP_STATUS_COMPLETED: 'Дії користувача та системи завершені, верифікація завершена.',
  TOOLTIP_STATUS_IN_REVIEW: 'Верифікація перебуває на перегляді у служби внутрішнього контролю.',
  TOOLTIP_STATUS_APPROVED: 'Верифікація схвалена службою внутрішнього контролю.',
  TOOLTIP_STATUS_REJECTED: 'Верифікація відхилена службою внутрішнього контролю.',
  TOOLTIP_REVIEWER_ASSIGNED: 'Рецензента призначено',

  // WARNINGS
  ALL_WARNING: 'Всі попередження',
  TRANSACTION_DELETE: 'Транзакцію видалено',
  DUPLICATE_PHONE: 'Дублікат телефону',
  DUPLICATE_EMAIL: 'Дублікат eмейлy',
  FILTER_AML_RESULT_UPDATED: 'Результати AML оновлено',
  FILTER_AML_CANNOT_STARTED: 'Неможливо розпочати AML',
  FILTER_AML_POSITIVE_CASES: 'Позитивні випадки AML',
  FILTER_APPLICANT_DUPLICATE_PHONE: 'Дубльований телефон заявника',
  FILTER_APPLICANT_DUPLICATE_DOCUMENT: 'Дубльований документ заявника',
  FILTER_EXPIRED_DOCUMENT: 'Термін дії документа закінчився',
  FILTER_APPLICANT_DUPLICATE_EMAIL: 'Дубльована електронна пошта заявника',
  FILTER_ADDITIONAL_DOCUMENT_STEP_ERROR: 'Помилка додаткового кроку документа',
  APPLICANT_DUPLICATE_PHONE:
    "Цей номер телефону вже пов'язаний з іншим заявником. Будь ласка, перевірте інформацію та надайте унікальний номер телефону.",
  APPLICANT_DUPLICATE_EMAIL:
    "Ця електронна адреса вже пов'язана з іншим заявником. Будь ласка, переконайтеся в унікальності та надайте іншу електронну адресу",
  EXPIRED_DOCUMENT:
    'Представлений документ застарів. Будь ласка, попросіть заявника завантажити дійсний документ для верифікації.',
  APPLICANT_DUPLICATE_DOCUMENT:
    "Цей документ вже пов'язаний з іншим заявником. Будь ласка, перегляньте оригінальні профілі",

  // Schedule applicant delete
  RESTORE_DATA: 'Відновити дані',
  CANCEL_KEEP_DATA_DELETED: 'Скасувати, залишити дані видаленими',
  RESTORE_APPLICANT_DATA: 'Відновити дані заявника',
  RESTORE_APPLICANT_DATA_DESCRIPTION:
    'Ця дія відновить доступ до даних заявника. Підтвердивши цю дію, заплановане видалення буде скасовано, і дані будуть відновлені.',
  DATA_WILL_BE_DELETED_1: 'Видалити документи, фотографії та відео',
  DATA_WILL_BE_DELETED_2: 'Видалити особисту інформацію',
  DATA_WILL_BE_DELETED_3: 'Видалити історію змін та логування',
  DATA_WILL_BE_DELETED_4: 'Видалити коментарі',
  SCHEDULE_DELETE: 'Запланувати видалення',
  CANCEL_KEEP_THE_DATA: 'Скасувати, залишити дані',
  CONFIRM_APPLICANT_DATA_DELETION: 'Підтвердити видалення даних заявника',
  CONFIRM_APPLICANT_DATA_DELETION_DESCRIPTION:
    'Дані заявника будуть назавжди видалені через 20 днів. Видалення включатиме наступне:',
  APPLICANT_WILL_BE_DELETED_DETAILS_WARNING: 'Дані заявника будуть назавжди видалені через {{days}} днів.',

  // DEACTIVATE SERVICE BUTTON
  DEACTIVATE: 'Деактивувати',
  DEACTIVATE_IF_YOU_DISAGREE_THE_RESULT: 'Натисніть, якщо ви не згодні з результатом.',
  DEACTIVATE_IF_YOU_DISAGREE_THE_RESULT_DESCRIPTION:
    'Ця дія перемістить дані до історії та деактивує службу.',
  DEACTIVATE_SERVICE_CONFIRM: 'Підтвердити відхилення кроку',
  DEACTIVATE_SERVICE_CONFIRM_DESCRIPTION:
    'Відмова від цього кроку вимагатиме від заявника повторити його після того, як ви надішлете нове посилання. Поточні дані зберігатимуться в обліковому записі для довідки. Ви впевнені, що бажаєте відхилити цей крок і запропонувати заявнику виконати його знову?',

  // ACTIVATE SERVICE BUTTON
  ACTIVATE: 'Активувати',
  ACTIVATE_SERVICE_CONFIRM: 'Натисніть "Підтвердити", щоб знову активувати сервіс.',
  ACTIVATE_SERVICE_CONFIRM_DESCRIPTION: 'Ця дія перемістить дані з Історії назад до активного статусу.',
  ACTIVATE_IF_YOU_DISAGREE_THE_RESULT: 'Натисніть, якщо ви не згодні з результатом.',
  ACTIVATE_IF_YOU_DISAGREE_THE_RESULT_DESCRIPTION:
    'Ця дія активує службу та перемістить її з історії до активної служби.',

  // SERVICES EMPTY STATE
  FRAME_1: 'Натисніть Створити посилання в трекері',
  FRAME_2: 'Скопіювати посилання в модальному вікні',
  FRAME_3: 'Надіслати посилання користувачеві',
  SERVICES_EMPTY_STATE_DESCRIPTION: 'Ви відхилили поточний етап перевірки',
  SERVICES_EMPTY_STATE_HINT: 'Ось що вам потрібно зробити далі',

  // APPLICANTS EMPTY STATE
  EMPTY_STATE_TITLE: 'Заявники',
  EMPTY_STATE_DESCRIPTION:
    'Жоден заявник ще не пройшов верифікацію. Як тільки заявники завершать свій верифікаційний процес, вони з`являться тут.',

  // EMAIL AND PHONE VERIFICATION SERVICE
  VERIFIED_POINT: 'Підтверджено / Не підтверджено',
  VALID_POINT: 'Дійсна / Недійсна',
  IS_DISPOSABLE_POINT: 'Тимчасовий провайдер електронної пошти',
  IS_PERSONAL_POINT: 'Особистий',
  SMTP_STATUS_POINT: 'Статус SMTP',
  DOMAIN_STATUS_POINT: 'Статус домену',
  HOSTING_PROVIDER_POINT: 'Провайдер хостингу',
  DETAILS: 'Подробиці',
  YES: 'Так',
  NO: 'Ні',
  MOBILE: 'Мобільний',
  ROAMING: 'Роумінг',
  CURRENT_NETWORK_POINT: 'Поточна мережа',
  NUMBER_TYPE: 'Тип номера',
  ROAMING_COUNTRY_CODE: 'Код країни роумінгу',
  EMAIL_OTP_VERIFICATION: 'Підтвердження електронної пошти OTP',
  PHONE_OTP_VERIFICATION: 'Перевірка телефону OTP',
  PHONE_HLR_VERIFICATION: 'Перевірка телефону HLR',
  PHONE_VOICE_OTP_VERIFICATION: 'Голосове підтвердження OTP',

  EMAIL_DETAILS: 'Електронна адреса, надана заявником',
  PHONE_DETAILS: 'Телефон, наданий заявником',
  VALID_DETAILS:
    'Перевіряє, чи відповідає номер телефону стандартним форматам телефонних номерів. Це гарантує, що номер правильно відформатований і здатний приймати дзвінки або повідомлення.',
  HLR_DETAILS:
    'Перевіряє, чи зареєстрований номер телефону в даний момент у мобільній мережі. Це визначає, чи є номер активним і на обслуговуванні.',
  MOBILE_DETAILS:
    'Перевіряє, чи належить наданий номер телефону мобільній мережі. Це відрізняє мобільні номери від інших типів телефонних номерів (наприклад, стаціонарних або VoIP).',
  ROAMING_DETAILS:
    'Вказує, чи використовується номер телефону в даний момент у режимі роумінгу, тобто чи активний він в іншій країні, ніж його домашня мережа.',
  CURRENT_NETWORK_DETAILS:
    'Визначає мобільну мережу або постачальника послуг, з яким в даний момент пов`язаний номер телефону. Це показує, з яким телекомунікаційним оператором пов`язаний користувач.',
  NUMBER_TYPE_DETAILS:
    'Категоризує тип номера телефону, наприклад, мобільний, стаціонарний (телефонний апарат), номер з преміум-тарифом, безкоштовний, VoIP або віртуальні номери.',
  ROAMING_COUNTRY_CODE_DETAILS:
    'Код країни, в якій номер телефону наразі знаходиться в роумінгу. Він порівнюється з місцезнаходженням або IP-адресою користувача.',
  LOCATION_DETAILS:
    'Геолокація поточного місцезнаходження номера телефону на основі телекомунікаційних даних. Це місцезнаходження часто визначається через вежі стільникового зв`язку, до яких підключений телефон.',

  VERIFIED_DETAILS:
    'Ця перевірка підтверджує, чи була адреса електронної пошти верифікована користувачем (наприклад, через посилання для підтвердження, надіслане на електронну пошту).',
  VALID_EMAIL_DETAILS:
    'Ця перевірка визначає, чи відповідає адреса електронної пошти правильним синтаксичним і форматувальним стандартам (наприклад, username@domain.com) і чи є домен дійсним та існує.',
  DISPOSAL_DETAILS:
    'Перевіряє, чи є адреса електронної пошти відомим одноразовим поштовим сервісом або пов’язана з сервісами дарквебу.',
  PERSONAL_DETAILS:
    'Визначає, чи належить адреса електронної пошти особистому домену (наприклад, Gmail, Yahoo, Outlook) на відміну від корпоративного або бізнес-домену.',
  SMTP_DETAILS:
    'Ця перевірка підтверджує, чи може адреса електронної пошти успішно отримувати листи шляхом комунікації з поштовим сервером одержувача (SMTP). Це гарантує, що поштову скриньку існує та вона працює.',
  DOMAIN_STATUS_DETAILS:
    'Перевіряє стан домену, пов’язаного з електронною адресою, гарантуючи, що домен дійсний, активний і не позначений як шкідливий. Це включає перевірку, чи зареєстрований домен або чи не призупинений.',
  HOSTING_PROVIDER_DETAILS:
    'Визначає хостинг-провайдера для домену електронної пошти. Це допомагає визначити, де розміщена інфраструктура електронної пошти, і може вказати, чи є провайдер надійним або пов`язаним із відомими факторами ризику.',

  // IP ADRESSES
  DEVICE_INFORMATION: 'Інформація про пристрій',
  IP_ADDRESSES_AND_ITS_PROPERTIES: 'Властивості IP-адреси',
  GEOLOCATION_DATA: 'Дані геолокації',
  PROVIDER_DETAILS: 'Деталі провайдерів',
  BROWSER_NAME_AND_VERSION: 'Назва та версія браузера',
  DEVICE: 'Пристрій',
  CITY: 'Місто',
  CITY_TOOLTIP:
    'Визначає місто, пов’язане з IP-адресою, на основі баз даних, які зіставляють діапазони IP з конкретними географічними регіонами.',
  COUNTRY_CODE: 'Код країни',
  COUNTRY_CODE_TOOLTIP:
    'Ідентифікує країну, пов’язану з IP-адресою. Код країни (у форматі ISO 3166) визначається через бази даних геолокації, які відстежують власників IP-адрес.',
  REGION: 'Регіон',
  REGION_TOOLTIP:
    'Ця перевірка визначає регіон (штат або провінцію) у межах країни, пов’язаний з IP-адресою. Як і перевірка міста, ці дані отримуються з геолокаційних служб.',
  IP_FORM_VALID: 'Правильний',
  IP_FORM_VALID_TOOLTIP:
    'Ця перевірка визначає, чи відповідає IP-адреса правильному формату (IPv4 або IPv6) і чи зареєстрована вона відповідними інтернет-органами (наприклад, ARIN, RIPE тощо).',
  BOGON: 'Богон',
  BOGON_TOOLTIP:
    'Ця перевірка визначає, чи належить IP-адреса до діапазону богонів. Богон-адреси — це не маршрутизовані адреси, які не призначені жодній законній організації, наприклад, приватні, зарезервовані або непризначені адреси.',
  HOSTING: 'Хостинг',
  HOSTING_TOOLTIP:
    'Визначає, чи пов’язана IP-адреса з провайдерами хостингу, дата-центрами або хмарною інфраструктурою. Це може означати, що IP-адреса належить серверу, а не особистому пристрою користувача.',
  PROXY: 'Проксі',
  PROXY_TOOLTIP:
    'Ця перевірка виявляє, чи використовує IP-адреса проксі-сервіс, який дозволяє користувачам приховувати свою реальну IP-адресу, перенаправляючи з’єднання через інший сервер.',
  VPN: 'VPN',
  VPN_TOOLTIP:
    'Ця перевірка визначає, чи належить IP-адреса VPN (віртуальній приватній мережі), що часто використовується для анонімізації реальної IP-адреси користувача шляхом шифрування трафіку і маршрутизації через віддалений сервер.',
  ISP: 'ISP',
  ISP_TOOLTIP:
    'Ця перевірка визначає, чи належить IP-адреса споживчому інтернет-провайдеру (ISP), який зазвичай використовується для домашніх підключень до інтернету.',
  PROVIDER_DOMAIN: 'Домен провайдера',
  PROVIDER_DOMAIN_TOOLTIP:
    'Визначає доменне ім’я провайдера послуг, пов’язаного з IP-адресою, зазвичай інтернет-провайдера або хостингової компанії.',
  PROVIDER_TYPE: 'Тип провайдера',
  PROVIDER_WEBSITE: 'Вебсайт провайдера',
  PROVIDER_DESCRIPTION: 'Опис провайдера',
  TRUE: 'Так',
  FALSE: 'Ні',

  MONITORING: 'Моніторинг',
  PROCESSING_HAS_STARTED:
    'Обробка розпочалася: Після активації документа заявника перевірка даних може зайняти деякий час. Дякуємо за ваше терпіння.',

  WALLET_NOT_FOUND_TITLE: 'Перевірка криптогаманця завершена – Гаманець не знайдено',
  WALLET_NOT_FOUND_DESCRIPTION:
    'Перевірку криптогаманця цього заявника завершено. Однак вказану адресу гаманця не знайдено. Будь ласка, перевірте правильність введених даних або за необхідності запросіть оновлену адресу у заявника.',

  PERMANENT_DELETE_TITLE: 'Остаточне видалення',
  PERMANENT_DELETE_DESCRIPTION:
    'Ви впевнені, що хочете видалити цього заявника? Цю дію неможливо скасувати, і всі пов’язані дані будуть видалені назавжди.',
};
