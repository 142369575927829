export const PERMISSIONS = {
  All: { value: '*', label: 'ENABLE_TO_ALL', enableAll: true },
  OnlyTestMode: { value: 'test', label: 'SANDBOX_MODE' },

  /* Applicant */
  ApplicantAll: { value: 'applicant:*', label: 'ALL', tabEnableAll: true },
  ListApplicants: { value: 'applicant:listApplicants', label: 'VIEW_ALL_APPLICANTS', accessLevel: 'ALL' },
  GetApplicant: { value: 'applicant:getApplicant', label: 'GET_APPLICANT', accessLevel: 'ALL' },
  DeleteApplicant: { value: 'applicant:deleteApplicant', label: 'DELETE_APPLICANT', accessLevel: 'ALL' },
  DownloadApplicant: {
    value: 'applicant:downloadApplicant',
    label: 'DOWNLOAD_APPLICANT',
    accessLevel: 'ALL',
  },
  AssignReviewerToApplicant: {
    value: 'applicant:assignReviewerToApplicant',
    label: 'ASSIGN_REVIEWER_TO_APPLICANT',
  },
  BatchAssignReviewerToApplicants: {
    value: 'applicant:batchAssignReviewerToApplicants',
    label: 'BATCH_ASSIGN_REVIEWER_TO_APPLICANTS',
  },
  BatchApproveApplicantReviews: {
    value: 'applicant:batchApproveReviews',
    label: 'BATCH_APPROVE_APPLICANT_REVIEWS',
  },
  BatchRejectApplicantReviews: {
    value: 'applicant:batchRejectReviews',
    label: 'BATCH_REJECT_APPLICANT_REVIEWS',
  },
  ResolveApplicantReviewWarning: {
    value: 'applicant:resolveReviewWarning',
    label: 'RESOLVE_APPLICANT_REVIEW_WARNING',
  },
  CreateApplicantComment: { value: 'applicant:createComment', label: 'CREATE_APPLICANT_COMMENT' },
  ListApplicantComments: { value: 'applicant:listComments', label: 'LIST_APPLICANT_COMMENTS' },
  UpdateApplicantDocumentType: {
    value: 'applicant:updateDocumentType',
    label: 'UPDATE_APPLICANT_DOCUMENT_TYPE',
  }, // NOT DONE
  UpdateApplicantDocumentGraphic: {
    value: 'applicant:updateDocumentGraphic',
    label: 'UPDATE_APPLICANT_DOCUMENT_GRAPHIC',
  }, // NOT DONE
  UpdateApplicantDocumentTextField: {
    value: 'applicant:updateDocumentTextField',
    label: 'UPDATE_APPLICANT_DOCUMENT_TEXT_FIELD',
  }, // NOT DONE
  UpdateApplicantFlow: { value: 'applicant:updateApplicantFlow', label: 'UPDATE_APPLICANT_FLOW' }, // NOT DONE
  DeactivateApplicantService: {
    value: 'applicant:deactivateService',
    label: 'DEACTIVATE_APPLICANT_SERVICE',
  },
  ListApplicantHistoryLogs: { value: 'applicant:listHistoryLogs', label: 'LIST_APPLICANT_HISTORY_LOGS' },
  ListApplicantHistoryServices: {
    value: 'applicant:listHistoryServices',
    label: 'LIST_APPLICANT_HISTORY_SERVICES',
  },
  // GetApplicantHistoryService: {
  //   value: 'applicant:getHistoryService',
  //   label: 'GET_APPLICANT_HISTORY_SERVICE',
  // },
  GetAmlResult: { value: 'applicant:getAmlResult', label: 'GET_AML_RESULT' },
  ApplicantStartAmlProcess: { value: 'applicant:startAmlProcess', label: 'START_AML_PROCESS' },
  ApplicantEnableAmlMonitoring: { value: 'applicant:enableAmlMonitoring', label: 'ENABLE_AML_MONITORING' },
  ApplicantDisableAmlMonitoring: { value: 'applicant:disableAmlMonitoring', label: 'DISABLE_AML_MONITORING' },
  ApplicantUpdateAmlMonitoring: { value: 'applicant:updateAmlMonitoring', label: 'UPDATE_AML_MONITORING' },
  CreateApplicantVerificationToken: {
    value: 'applicant:createApplicantVerificationToken',
    label: 'CREATE_APPLICANT_VERIFICATION_TOKEN',
  },
  ApplicantCreateAmlProfileComment: {
    value: 'applicant:createAmlProfileComment',
    label: 'CREATE_AML_PROFILE_COMMENT',
  },
  ApplicantListAmlProfileComments: {
    value: 'applicant:listAmlProfileComments',
    label: 'LIST_AML_PROFILE_COMMENTS',
  },
  ListAmlProfile: { value: 'applicant:listAmlProfiles', label: 'LIST_AML_PROFILE' },
  ApplicantGetAmlProfile: { value: 'applicant:getAmlProfile', label: 'GET_AML_PROFILE' },
  ApplicantUpdateAmlProfileStatus: {
    value: 'applicant:updateAmlProfileStatus',
    label: 'UPDATE_AML_PROFILE_STATUS',
  },
  ApplicantExportAmlData: { value: 'applicant:exportAmlData', label: 'EXPORT_AML_DATA' },

  /* Application */
  ApplicationAll: { value: 'application:*', label: 'ALL', tabEnableAll: true },
  ListApplications: { value: 'application:listApplications', label: 'VIEW_ALL_APPLICATIONS' },
  GetApplication: { value: 'application:getApplication', label: 'VIEW_APPLICATION' },
  CreateApplication: { value: 'application:createApplication', label: 'CREATE_APPLICATIONS' },
  DeleteApplication: { value: 'application:deleteApplication', label: 'DELETE_ALL_APPLICATIONS' },
  UpdateApplication: { value: 'application:updateApplication', label: 'EDIT_ALL_APPLICATIONS' },
  SetApplicationSecretKey: { value: 'application:setSecretKey', label: 'UPDATE_SECRET_API_KEY' },

  /* Verification */
  // VerificationAll: { value: 'verification:*', label: 'ALL', tabEnableAll: true },
  // ListVerifications: {
  //   value: 'verification:listVerifications',
  //   label: 'VIEW_VERIFICATIONS',
  //   accessLevel: 'ALL',
  // },
  // GetVerification: {
  //   value: 'verification:getVerification',
  //   label: 'VIEW_VERIFICATION_DETAILS',
  //   accessLevel: 'ALL',
  // },
  // DeleteVerification: {
  //   value: 'verification:deleteVerification',
  //   label: 'DELETE_VERIFICATIONS',
  //   accessLevel: 'ALL',
  // },
  // DownloadVerification: {
  //   value: 'verification:downloadVerification',
  //   label: 'DOWNLOAD_VERIFICATIONS',
  //   accessLevel: 'ALL',
  // },
  // AssignReviewerToVerification: {
  //   value: 'verification:assignReviewerToVerification',
  //   label: 'ASSIGN_REVIEWER_TO_VERIFICATION',
  // },
  // BatchAssignReviewerToVerifications: {
  //   value: 'verification:batchAssignReviewerToVerifications',
  //   label: 'BATCH_REVIEWER_TO_VERIFICATION',
  // },
  // BatchApproveVerificationReviews: {
  //   value: 'verification:batchApproveReviews',
  //   label: 'BATCH_APPROVE_VERIFICATION_REVIEWS',
  // },
  // BatchRejectVerificationReviews: {
  //   value: 'verification:batchRejectReviews',
  //   label: 'BATCH_REJECT_VERIFICATION_REVIEWS',
  // },
  // UpdateVerificationDocumentType: {
  //   value: 'verification:updateVerificationDocumentType',
  //   label: 'UPDATE_VERIFICATION_DOCUMENT_TYPE',
  // }, // NOT DONE
  // UpdateVerificationDocumentGraphic: {
  //   value: 'verification:updateVerificationDocumentGraphic',
  //   label: 'UPDATE_VERIFICATION_DOCUMENT_GRAPHIC',
  // }, // NOT DONE
  // UpdateVerificationDocumentTextField: {
  //   value: 'verification:updateVerificationDocumentTextField',
  //   label: 'UPDATE_VERIFICATION_DOCUMENT_TEXT_FIELD',
  // }, // NOT DONE
  // ListVerificationHistoryLogs: {
  //   value: 'verification:listVerificationHistoryLogs',
  //   label: 'LIST_VERIFICATION_HISTORY_LOGS',
  // },
  // ResendLastFailedVerificationWebhooks: {
  //   value: 'verification:resendFailedWebhooks',
  //   label: 'RESEND_LAST_FAILED_VERIFICATION_WEBHOOKS',
  // }, // NOT DONE

  /* Company */
  CompanyAll: { value: 'company:*', label: 'ALL', tabEnableAll: true },
  GetCompanyInfo: { value: 'company:getCompanyInfo', label: 'VIEW_COMPANY_INFO' },
  UpdateCompanyInfo: { value: 'company:updateCompanyInfo', label: 'EDIT_COMPANY_INFO' },
  DeleteCompanyInfo: { value: 'company:deleteCompanyInfo', label: 'DELETE_COMPANY_INFO' }, // NOT DONE

  /* Workflow */
  WorkflowsAll: { value: 'workflows:*', label: 'ALL', tabEnableAll: true },
  ListFlows: { value: 'workflows:listFlows', label: 'VIEW_FLOWS' },
  GetFlow: { value: 'workflows:getFlow', label: 'VIEW_FLOW_DETAILS' }, // NOT DONE
  CreateFlow: { value: 'workflows:createFlow', label: 'CREATE_FLOW' },
  UpdateFlow: { value: 'workflows:updateFlow', label: 'EDIT_FLOW' },
  DeleteFlow: { value: 'workflows:deleteFlow', label: 'DELETE_FLOW' },
  DuplicateFlow: { value: 'workflows:duplicateFlow', label: 'DUPLICATE_FLOW' },

  /* Team */
  TeamAll: { value: 'team:*', label: 'ALL', tabEnableAll: true },
  ListUsers: { value: 'team:listUsers', label: 'VIEW_USERS' },
  GetUser: { value: 'team:getUser', label: 'VIEW_USER_DETAILS' },
  DeleteUser: { value: 'team:deleteUser', label: 'DELETE_USER' },
  UpdateUser: { value: 'team:updateUser', label: 'UPDATE_USER' },
  UpdateUserPhoto: { value: 'team:updateUserPhoto', label: 'UPDATE_USER_PHOTO' },
  UpdateUserStatus: { value: 'team:updateUserStatus', label: 'UPDATE_USER_STATUS' },
  ListInvites: { value: 'team:listInvites', label: 'VIEW_INVITES' },
  GetInvite: { value: 'team:getInvite', label: 'VIEW_INVITE_DETAILS' },
  UpdateInvite: { value: 'team:updateInvite', label: 'UPDATE_INVITE_DATA' },
  DeleteInvite: { value: 'team:deleteInvite', label: 'DELETE_INVITE' },
  CreateInvite: { value: 'team:createInvite', label: 'CREATE_INVITE' },
  ListRoles: { value: 'team:listRoles', label: 'VIEW_ROLES' },
  GetRole: { value: 'team:getRole', label: 'VIEW_ROLE_DETAILS' },
  CreateRole: { value: 'team:createRole', label: 'CREATE_ROLE' },
  UpdateRole: { value: 'team:updateRole', label: 'UPDATE_ROLE' },
  DeleteRole: { value: 'team:deleteRole', label: 'DELETE_ROLE' },

  /* Analytics */
  AnalyticsAll: { value: 'analytics:*', label: 'ALL', tabEnableAll: true },
  ListAnalytics: { value: 'analytics:listAnalytics', label: 'VIEW_ANALYTICS' },

  /* Billing */
  BillingAll: { value: 'billing:*', label: 'ALL', tabEnableAll: true },
  GetBillingStatistics: { value: 'billing:getBillingStatistics', label: 'VIEW_BILLING_STATISTICS' },
  ListInvoices: { value: 'billing:listInvoices', label: 'VIEW_INVOICES' },

  /* Tariff */
  TariffAll: { value: 'tariff:*', label: 'ALL', tabEnableAll: true },
  GetCurrentTariffPlanPrice: {
    value: 'tariff:getCurrentTariffPlanPrice',
    label: 'VIEW_CURRENT_TARIFF_PLAN_PRICE',
  },

  /* Questionnaire */
  QuestionnaireAll: { value: 'questionnaire:*', label: 'ALL', tabEnableAll: true },
  CreateQuestionnaireSchema: {
    value: 'questionnaire:createQuestionnaireSchema',
    label: 'CREATE_QUESTIONNAIRE_SCHEMA',
  },
  UpdateQuestionnaireSchema: {
    value: 'questionnaire:updateQuestionnaireSchema',
    label: 'UPDATE_QUESTIONNAIRE_SCHEMA',
  },
  GetQuestionnaireSchema: {
    value: 'questionnaire:getQuestionnaireSchema',
    label: 'VIEW_QUESTIONNAIRE_SCHEMA',
  },
  ListQuestionnaireSchemas: {
    value: 'questionnaire:listQuestionnaireSchemas',
    label: 'VIEW_LIST_OF_QUESTIONNAIRES',
  },
  DeleteQuestionnaireSchema: {
    value: 'questionnaire:deleteQuestionnaireSchema',
    label: 'DELETE_QUESTIONNAIRE_SCHEMA',
  },

  // AML SCAN
  AmlScanAll: { value: 'aml:*', label: 'ALL', tabEnableAll: true },
  StartAmlProcess: { value: 'aml:startAmlProcess', label: 'START_AML_PROCESS' },
  ListAmls: { value: 'aml:listAmls', label: 'VIEW_LIST_AML_RESULTS' },
  GetAml: { value: 'aml:getAml', label: 'GET_AML' },
  GetAmlProfile: { value: 'aml:getAmlProfile', label: 'GET_AML_PROFILE' },
  ExportAmlData: { value: 'aml:exportAmlData', label: 'EXPORT_AML_DATA' },
  EnableAmlMonitoring: { value: 'aml:enableAmlMonitoring', label: 'ENABLE_AML_MONITORING' },
  DisableAmlMonitoring: { value: 'aml:disableAmlMonitoring', label: 'DISABLE_AML_MONITORING' },
  UpdateAmlMonitoring: { value: 'aml:updateAmlMonitoring', label: 'UPDATE_AML_MONITORING' },
  CreateAmlProfileComment: { value: 'aml:createAmlProfileComment', label: 'CREATE_AML_PROFILE_COMMENT' },
  ListAmlProfileComments: { value: 'aml:listAmlProfileComments', label: 'LIST_AML_PROFILE_COMMENTS' },
  UpdateAmlProfileStatus: { value: 'aml:updateAmlProfileStatus', label: 'UPDATE_AML_PROFILE_STATUS' },
  UpdateAmlMatchStatus: { value: 'aml:updateAmlMatchStatus', label: 'UPDATE_AML_MATCH_STATUS' },
  UpdateAmlCaseStatus: { value: 'aml:updateAmlCaseStatus', label: 'UPDATE_AML_CASE_STATUS' },

  // Crypto AML
  CryptoAmlAll: { value: 'cryptoAml:*', label: 'ALL' },
  CryptoAmlSearch: { value: 'cryptoAml:search', label: 'CRYPTO_AML_SEARCH' },
  CryptoAmlList: { value: 'cryptoAml:list', label: 'CRYPTO_AML_LIST' },
  GetCryptoAml: { value: 'cryptoAml:get', label: 'GET_CRYPTO_AML' },
  GetCryptoAmlTimeline: { value: 'cryptoAml:getTimeline', label: 'GET_CRYPTO_AML_TIMELINE' },
  UpdateCryptoAmlStatus: { value: 'cryptoAml:updateStatus', label: 'UPDATE_CRYPTO_AML_STATUS' },
  GetCryptoAmlPdfReport: { value: 'cryptoAml:getPdfReport', label: 'GET_CRYPTO_AML_PDF_REPORT' },
  ExportCryptoAmlData: { value: 'cryptoAml:exportData', label: 'EXPORT_CRYPTO_AML_DATA' },
  AddCryptoAmlWalletToMonitoring: {
    value: 'cryptoAml:addWalletToMonitoring',
    label: 'ADD_CRYPTO_AML_WALLET_TO_MONITORING',
  },
  RemoveCryptoAmlWalletFromMonitoring: {
    value: 'cryptoAml:removeWalletFromMonitoring',
    label: 'REMOVE_CRYPTO_AML_WALLET_TO_MONITORING',
  },
  CreateCryptoAmlComment: { value: 'cryptoAml:createComment', label: 'CREATE_CRYPTO_AML_COMMENT' },
  ListCryptoAmlComments: { value: 'cryptoAml:listComments', label: 'LIST_CRYPTO_AML_COMMENTS' },
  ListCryptoAmlEntities: { value: 'cryptoAml:listEntities', label: 'LIST_CRYPTO_AML_ENTITIES' },
  GetCryptoAmlEntity: { value: 'cryptoAml:getEntity', label: 'GET_CRYPTO_AML_ENTITY' },
  AssignAddressToApplicant: {
    value: 'cryptoAml:assignAddressToApplicant',
    label: 'ASSIGN_ADDRESS_TO_APPLICANT',
  },
  BulkAssignAddressToApplicant: {
    value: 'cryptoAml:bulkAssignAddressToApplicant',
    label: 'BULK_ASSIGN_ADDRESS_TO_APPLICANT',
  },
};

export const PERMISSIONS_GROUPS = {
  GENERAL: 'GENERAL',
  ANALYTICS: 'ANALYTICS',
  APPLICANT: 'APPLICANT',
  COMPANY: 'COMPANY',
  TEAM: 'TEAM',
  APPLICATION: 'APPLICATION',
  WORKFLOWS: 'WORKFLOWS',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  TARIFF: 'TARIFF',
  BILLING: 'BILLING',
  // VERIFICATION: 'VERIFICATION',
  AML: 'AML',
  CRYPTOAML: 'CRYPTOAML',
  NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
};

export const ACCESS_LEVELS = { ALL: 'ALL', ONLY_ASSIGNED: 'RELATED' };
