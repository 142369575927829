/* eslint-disable camelcase */
import axios from 'axios';

const ACCESS_TOKEN_KEY = 'jwt_access_token';

class AxiosManagerService {
  #instances = new Map();

  #mappedHandlers = new Map();

  pendingRequests = [];

  tokenExpired = false;

  bindHttpErrorsHandlers(mappedHandlers) {
    this.#mappedHandlers = mappedHandlers;
  }

  getInstance(host, path) {
    const baseURL = `${host}/${path}`;

    if (!this.#instances.has(host)) {
      const instance = axios.create({ baseURL });
      this.#instances.set(host, instance);
      return instance;
    }

    const parentInstance = this.#instances.get(host);
    const instance = axios.create({ baseURL });

    instance.defaults.headers = parentInstance.defaults.headers;
    this.setInterceptors(instance);

    return instance;
  }

  setHeader(name, value) {
    this.#instances.forEach((instance) => {
      instance.defaults.headers.common[name] = value;
    });
  }

  setInterceptors(axiosInstance) {
    axiosInstance.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalRequest = err.config;
        const statusCode = err.response?.data?.statusCode;
        if (!statusCode) throw err;

        const callback = this.#mappedHandlers.get(statusCode);

        if (statusCode === 4001) {
          if (this.tokenExpired) {
            return this.pendingRequests.push(originalRequest);
          }

          this.tokenExpired = true;
          const { access_token } = await callback();

          originalRequest.headers.Authorization = `Bearer ${access_token}`;

          this.pendingRequests.forEach((config) =>
            axios.request({ ...config, headers: { Authorization: `Bearer ${access_token}` } })
          );
          this.pendingRequests = [];

          this.tokenExpired = false;

          return axios.request(originalRequest);
        }

        if (callback) {
          await callback?.();
        }

        throw err;
      }
    );
  }

  stringifyError = (err) => err.response?.data?.error || err.message;
}

export const AxiosManager = new AxiosManagerService();
