export default {
  LOGS: 'Логи',
  PROFILE: 'Профіль',
  MEDIUM: 'Середній',
  LOW: 'Низький',
  HIGH: 'Високий',
  TIMELINE: 'Хронологія',
  BACK_TO_RESULT: 'Назад до результату',
  CONFIRM: 'Підтвердити',
  DOWNLOAD_PDF: 'Завантажити PDF',
  BACK_TO_APPLICANT: 'Повернутися до заявника',
  ASSIGN_RESULTS: 'Призначити результати',
  ASSIGN_RESULTS_DESCRIPTION:
    'Результати присвоєння адреси заявнику із зазначенням успішного чи неуспішного.',

  // INFORMATION
  ADDRESS: 'Адреса',
  AUTHOR: 'Автор',
  BLOCKCHAIN: 'Блокчейн',
  BALANCE: 'Баланс',
  REPORT_GENERATED: 'Звіт створено',
  ADDRESS_INFORMATION: 'Інформація про адресу',
  ADDRESS_RISK_REPORT: 'Звіт про ризики адреси',
  ADD_TO_MONITORING: 'Додати до моніторингу',
  REFRESH: 'Оновити',
  TRANSACTION_RECEIVED: 'Отримана транзакція',
  TOTAL_RECEIVED: 'Усього отримано',
  FIRST_SEEN: 'Вперше побачено',
  TOTAL_TRANSACTIONS: 'Усього транзакцій',
  TRANSACTIONS_SENT: 'Відправлені транзакції',
  TOTAL_SENT: 'Усього відправлено',
  LAST_SEEN: 'Останнє виявлення',
  TRANSACTION_INFORMATION: 'Інформація про транзакцію',
  BLOCK: 'Блок',
  TIMESTAMP: 'Позначка часу',
  TRANSACTION_HASH: 'Хеш транзакції',
  TOTAL_AMOUNT: 'Загальна сума',

  // GL_SCORE
  GL_SCORE: 'GL Рейтинг',
  SOURCE_OF_FUNDS: 'Джерело коштів',
  USE_OF_FUNDS: 'Використання коштів',
  ADDRESS_RISK: 'Ризик адреси',

  // RISK_EXPOSURE
  RISK_EXPOSURE: 'Експозиція ризиків',
  FULL_EXPOSURE: 'Повна експозиція',
  RISK_LABELS: 'Мітки ризиків',
  ENTITY: 'Суб’єкт',
  HIGHEST_AMOUNT: 'Найбільша сума',
  LOWEST_AMOUNT: 'Найменша сума',
  HIGHEST_RISK: 'Найвищий ризик',
  LOWEST_RISK: 'Найнижчий ризик',
  LABEL_NAME_DESC: 'Назва мітки (за спаданням)',
  LABEL_NAME_ASC: 'Назва мітки (за зростанням)',
  EVALUATED_TRANSACTIONS: 'Оцінені транзакції',
  SOURCE_OF_FUNDS_TRANSACTIONS: 'Транзакції джерел коштів',
  USE_OF_FUNDS_TRANSACTIONS: 'Транзакції використання коштів',
  RISK_LABEL: 'Мітка ризику',
  AMOUNT: 'Сума',
  LEAF_TX: 'Кінцева транзакція',
  ROOT_TX: 'Коренева транзакція',
  DEPTH: 'Глибина',
  DATE_AND_TIME: 'Дата та час',

  // ENTITY_EXPLORER
  OVERVIEW: 'Огляд',
  ENTITY_EXPLORER: 'Досліджувач суб’єктів',
  ENTITY_NAME: 'Назва суб’єкта',
  LEGAL_NAME: 'Юридична назва',
  PARENT_COMPANY_OR_OWNERSHIP: 'Материнська компанія / власність',
  ENTITY_WEBSITE: 'Вебсайт суб’єкта',
  DOMICILED_COUNTRY: 'Країна реєстрації',
  STATUS: 'Статус',
  TYPE: 'Тип',
  ASSOCIATED_TAGS: 'Пов’язані мітки',
  DESCRIPTION: 'Опис',
  ENTITY_DETAILS: 'Деталі суб’єкта',
  PROVIDER_SERVICES: 'Надані послуги',
  PRIMARY_OPERATIONAL_REGIONS: 'Основні регіони діяльності',
  RESTRICTED_JURISDICTIONS: 'Обмежені юрисдикції',
  FIAT_CURRENCY_TRADING: 'Торгівля фіатною валютою',
  OFFICE_ADDRESSES: 'Адреси офісів',
  LANGUAGES: 'Мови',
  PRIVACY_COINS_SUPPORTED_FLAG: 'Підтримка приватних монет',
  SOCIAL_NETWORK_LINK: 'Посилання на соціальну мережу',
  REGULATORY_COMPLIANCE: 'Регуляторна відповідність',
  LOCAL_AUTHORITY: 'Місцевий орган влади',
  LICENSE_NUMBER: 'Номер ліцензії',
  COUNTRY: 'Країна',
  LICENSE_TYPE: 'Тип ліцензії',
  REGISTERED_NUMBER: 'Реєстраційний номер',
  START_DATE: 'Дата початку',
  PAYMENT_SERVICE: 'Платіжна послуга',
  SYSTEM_NAME: 'Назва системи',
  PAYMENT_TYPE: 'Тип платежу',
  PAYMENT_METHODS: 'Способи оплати',
  WEBSITE: 'Вебсайт',
  CONTACTS: 'Контакти',
  PEOPLE: 'Люди',
  POSITION: 'Посада',
  NAME: 'Ім’я',
  DEPARTMENTS: 'Відділи',
  DEPARTMENT_NAME: 'Назва відділу',
  ENTER_THE_ENTITY_NAME: 'Введіть назву суб’єкта',
  SEARCH_RETURN_NO_RESULTS: 'Пошук не дав результатів',
  TOKEN_BALANCE: 'Баланс токенів',
  ACCOUNT_HOLDER: 'Власник рахунку',

  SEARCH: 'Пошук',
  TRANSACTION_MONITORING: 'Моніторинг транзакцій',
  HEADER_TITLE: 'Моніторинг та захист транзакцій з',
  HEADER_TIP:
    'Заповніть поля нижче, щоб почати відстежувати активність гаманця та ефективно виявляти та усувати потенційні ризики.',

  SCAN_RESULT: 'Результат сканування',
  NO_SEARCH_RESULTS: 'Результати не знайдені',
  NO_SEARCH_RESULTS_DESCRIPTION:
    'На жаль, ваш пошук не дав результатів. Спробуйте інші критерії або перевірте написання',

  TYPE_TO_SEARCH_RISK_REPORT:
    'Введіть адресу або хеш транзакції в рядок пошуку, щоб отримати звіт про джерела коштів.',

  // Score requests
  SCORE_REQUESTS: 'Запити на оцінку',
  APPROVED: 'Затверджено',
  REJECTED: 'Відхилено',
  UNKNOWN: 'Невідомо',
  TRANSACTION: 'Оцінка транзакції',
  WALLET: 'Оцінка адреси',
  ALL_ASSETS: 'Усі активи',
  OTHER_ASSETS: 'Інші активи',
  ALL_STATUS: 'Усі статуси',
  ALL_BLOCKCHAINS: 'Усі блокчейни',
  SCORE: 'Оцінка',
  TOKEN: 'Токен',
  ADD_WALLET_ADDRESS_OR_TRANSACTION: 'Додати адресу гаманця або транзакцію',

  START_MONITORING: 'Почати моніторинг',
  STOP_MONITORING: 'Зупинити моніторинг',

  SEARCH_STARTED:
    'Ваш пошук транзакції розпочато! Ми аналізуємо дані та повідомимо вас, щойно результати будуть готові.',
  SEARCH_COMPLETED: 'Ваш пошук транзакції завершено! Результати готові для перегляду.',

  // Monitoring
  REMOVE_FROM_MONITORING: 'Видалити з моніторингу',
  REMOVE_MONITORING: 'Підтвердження деактивації моніторингу',
  REMOVE_MONITORING_DESCRIPTION:
    'Ви збираєтесь скасувати поточний моніторинг цього гаманця/транзакції. Після скасування ви більше не отримуватимете сповіщення або оцінки ризиків для відстеження відповідності.',
  ADD_MONITORING: 'Підтвердження активації моніторингу',
  ADD_MONITORING_DESCRIPTION:
    'Ви збираєтесь активувати поточний моніторинг цього гаманця/транзакції. Це забезпечить сповіщення в реальному часі для дотримання регуляторних вимог.',
  MONITORING_ADDED: 'Моніторинг транзакцій/гаманця успішно активовано.',
  MONITORING_REMOVED: 'Моніторинг транзакцій/гаманця зупинено.',

  // Change status
  CONFIRM_CHANGE_STATUS: 'Підтвердження зміни статусу',
  CONFIRM_CHANGE_STATUS_DESCRIPTION: 'Ви впевнені, що хочете змінити статус цієї адреси?',

  MONITORED: 'Моніториться',
  MONITORED_TOOLTIP:
    'Ця адреса гаманця моніториться, ви можете отримувати швидкі та детальні звіти, а також сповіщення у разі нових вхідних транзакцій, нових контрагентів або виявлення ризиків AML/CTF. Цей звіт створено на основі останніх 1000 вхідних транзакцій запитуваної адреси.',

  // Assign to applicant
  ASSIGN: 'Призначити',
  ASSIGN_TO_APPLICANT: 'Призначити заявнику',
  ASSIGN_TO_APPLICANT_DESCRIPTION:
    'Прив’язує адресу гаманця до заявника, забезпечуючи точне відстеження та чітку відповідальність.',
  EXTERNAL_USER_ID: 'Зовнішній ID користувача',
  SUCCESSFULLY_ASSIGNED: 'Успішно призначено',
  APPLICANT: 'Заявник',

  CRYPTO_AML_STATUS_UPDATED: 'Статус Crypto AML для заявника оновлено',
  CRYPTO_AML_EXPORTED_DATA: 'Дані Crypto AML для заявника успішно експортовано',
  CRYPTO_CREATED_AML_COMMENT: 'Додано новий коментар щодо статусу Crypto AML заявника',
  CRYPTO_AML_MONITORING_DISABLED: 'Моніторинг Crypto AML для заявника вимкнено',
  CRYPTO_AML_MONITORING_ENABLED: 'Моніторинг Crypto AML для заявника активовано',
  CRYPTO_AML_START_PROCESS: 'Процес Crypto AML для заявника ініційовано',
  CRYPTO_RETRIEVED_AML_RESULT: 'Результати Crypto AML для заявника успішно отримано',
  CRYPTO_RETRIEVED_AML_RESULT_ERROR:
    'Виникла помилка під час спроби отримати результати Crypto AML для заявника',
  CRYPTO_AML_ASSIGNED_TO_APPLICANT: 'Цей випадок Crypto AML було прив’язано до заявника',
  CRYPTO_AML_REFRESH_PROCESS: 'Процес Crypto AML для заявника оновлено для отримання нових результатів',
};
