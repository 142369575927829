const locale = {
  LOGIN_TITLE: 'Sign in',
  REDIRECT_SIGN_UP: "Don't have an account?",
  REDIRECT_SIGN_UP_LINK: 'Sign up',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN_BUTTON: 'Login',
  LABEL_LOGIN: 'Email/Phone',
  LABEL_PASS: 'Password',
  YOU_MUST_ENTER_CODE: 'You must enter code',
  CODE_IS_TOO_SHORT: 'Code is too short - should be 6 chars',

  YOU_CAN_SEND_LINK_AGAIN: 'You can send link again in {{seconds}} seconds',
  FORGOT_PASSWORD_TITLE: 'Forgot password?',
  FORGOT_PASSWORD_SUBTITLE: 'Fill the form to reset your password',
  SEND_RESET: 'Send reset link',
  GO_BACK_LOGIN: 'Return to ',

  RESET_PASSWORD_TITLE: 'Recovery your password',
  RESET_PASSWORD_SUBTITLE: 'Create a new password for your account',
  RESET_PASSWORD: 'Reset password',
  RESTORE_CODE: 'Restore code',
  CONFIRM_PASSWORD: 'Confirm Password',
  GO_BACK: 'Go back to ',
  LOGIN: ' sign in',
  MIN_LENGTH_CODE: 'Restore code is too short - should be 6 chars',
  VALIDATION_PASSWORD: 'Password is too short - should be 8 chars minimum',
  VALIDATION_CONFIRM: 'Passwords do not match',

  SIGN_UP: 'Sign up',
  SIGN_IN: 'Sign in',
  SIGN_IN_SUBTITLE: 'Already have an account?',
  FULL_NAME: 'Full name',
  COMPANY_NAME: 'Company',
  PHONE: 'Phone',
  POSITION: 'Position',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  SIGN_UP_BUTTON: 'Sign Up',

  REGISTRATION_TITLE: 'Initializing Two-Factor Authentication',
  REGISTRATION_TEXT: 'Please use your Google Authenticator to scan this QR code',
  VERIFICATION_CODE: 'Verification Code',
  VALIDATION_CODE: 'Min character length is 6',
  PASSWORD_BUTTON: 'Confirm',
  SECRET_CODE: 'You can use the setup key to manually configure your authenticator app',

  // OTP
  HELPER_TEXT_OTP: 'Confirmation code has been sent to <strong>{{email}}</strong>',
  LABEL_OTP_CODE: 'Enter confirm code',
  CONFIRM: 'Confirm',
  OTP_SEND_AGAIN_TEXT: 'You can send code again after {{seconds}} seconds',
  REQUEST_CODE_AGAIN: 'Request code again',

  FULL_NAME_HELPER: 'You must enter your full name',
  FULL_NAME_ERROR: 'You must enter a valid full name',

  COMPANY_NAME_HELPER: 'You must enter your company name',
  COMPANY_NAME_ERROR: 'You must enter a valid company name',

  PHONE_HELPER: 'You must enter a phone number in format +380000000000',
  PHONE_ERROR: 'You must enter a valid phone number',

  POSITION_HELPER: 'You must enter your position',
  POSITION_ERROR: 'You must enter a valid position',

  EMAIL_HELPER: 'You must enter a email',
  EMAIL_ERROR: 'You must enter a valid email',

  PASSWORD_HELPER: 'Please enter your password',
  PASSWORD_ERROR: 'Password is too short - should be 8 chars minimum',

  ERROR_LOGIN: 'You must enter a valid email or phone number',
  HELPER_LOGIN: 'You must enter a email or phone number',

  TERMS_CONDITIONS_CHECK: 'The terms and conditions must be accepted',
  TERMS_CONDITIONS_AGREE: 'I agree to the',
  PRIVACY_STATEMENT: 'Privacy Statement',

  TERMS_SERVICE_CHECK: 'The terms and service must be accepted',
  TERMS_SERVICE_AGREE: 'Registering to this website, you accept out',
  TERMS_USE: 'Terms of Service',
  AUTH_CONTENT_TITLE: 'A fully automated KYC & AML tool for fintech',
  AUTH_CONTENT_DESCRIPTION: 'Stay compliant with your customers & world',

  EMAIL_SUB_VALIDATION: 'You cannot enter a sub-email. Please enter a valid email address',
};

export default locale;
