import { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from '@reduxjs/toolkit';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';

import jwtService from '../services/jwtService';

import { setUserData, logoutUser } from './store/userSlice';
import { tokenLogin, refreshTokenLogin } from './store/loginSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on('onAutoLogin', () => {
        this.props.showMessage({ message: 'Logging in with JWT' });

        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);

            resolve();

            this.props.showMessage({ message: 'Logged in with JWT' });
          })
          .catch((error) => {
            this.props.showMessage({ message: error.message });

            resolve();
          });
      });

      jwtService.on('onAutoLogout', (message) => {
        if (message) {
          this.props.showMessage({ message });
        }

        /**
         * Sign in with refresh token and retrieve user data from Api
         */

        jwtService
          .signInWithRefreshToken()
          .then(({ user }) => {
            this.props.setUserData(user);
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            this.props.logout();
            this.props.showMessage({ message: err.message });

            resolve();
          });
      });

      jwtService.on('onNoAccessToken', () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
  }
}

const mapStateToProps = ({ auth: { login } }) => ({
  errors: login.errors,
  success: login.success,
  loading: login.loading,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshTokenLogin,
      tokenLogin,
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
